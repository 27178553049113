import React from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import BackButton from '../components/back-button'
import { rhythm, scale } from '../utils/typography'

export default function Tag({ data, pageContext }) {
    const posts = data.allMarkdownRemark.edges
    const tag = pageContext.tag

    return (
        <Layout>
            <SEO title={`Tagged as ${tag}`} keywords={[`${tag}`, 'blog', 'rohit mundra']} />
            <BackButton to='/' title='Home' />
            <h4 style={{ marginBottom: rhythm(1) }}>{`Posts tagged as "${tag}"`}</h4>
            <ul>
                {posts.map(post => (
                    <li key= { post.node.id }>
                        <Link to={post.node.frontmatter.path}>
                            <span style={{ fontSize: "1.4rem" }}>
                                { post.node.frontmatter.title }
                            </span>
                        </Link>
                    </li>
                ))}
            </ul>
            <Link to='/tags'>Browse all tags</Link>
        </Layout>
    )
}

export const tagQuery = graphql`
    query BlogsByTag($tag: String) {
        allMarkdownRemark(
            filter: { frontmatter: { tags: { in: [$tag] } } }){
                edges {
                    node {
                        id
                        frontmatter {
                            title
                            path
                            date
                            author
                            tags
                        }
                    }
                }
        }
    }
`